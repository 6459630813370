const FreeSessionButton = ({url, children}) => {
    return (
        <a 
            href={url} 
            target='_blank' 
            rel='noopener noreferrer' 
            className='button-free-session'
        >
            {children}
        </a>
    );
}

export default FreeSessionButton;