import Navbar from "./Navbar";
import Footer from "./Footer";
import './Coaching.css';

const Coaching = () => {
    const coachingOverview = 'At YourVoice English Coaching, I offer personalized, one-on-one coaching tailored to help you meet your specific language goals. Whether you’re looking to excel in professional settings, boost your conversational fluency, or gain confidence in understanding American culture, I’m here to support you every step of the way.'
    const businessEnglish = 'Enhance your professional communication with customized coaching focused on business language skills. We’ll work on presentations, meetings, emails, and industry-specific vocabulary, all designed to help you succeed in your career.'
    const conversationalEnglish = 'Gain confidence in everyday conversations with coaching that helps you practice real-life speaking scenarios. We’ll focus on improving fluency, pronunciation, and natural expressions, so you can feel comfortable in any situation, from casual chats to more formal interactions.'
    const americanCulture = 'Understanding the cultural context behind language is key to sounding fluent. In this coaching session, we’ll explore common American idioms, slang, and cultural references that will help you communicate more like a native speaker and navigate cultural differences with ease.'
    return (
        <div className="coaching-page">
            <Navbar />
            <section className="coaching-overview">
                <div className="title-left">
                    <h1>Coaching Overview</h1>
                    <p>{coachingOverview}</p>
                </div>
                <div className='services-coaching'>
                    <article className='service-icon'>
                        <img className='img-descriptive' src={`${process.env.PUBLIC_URL}/img/business_coaching.webp`} alt="Business icon" />
                        <h4 style={{color:'white', width:'100%', textAlign: 'start'}}>Business English</h4>
                        <p style={{color:'white', width:'100%', textAlign: 'start'}}>{businessEnglish}</p>
                    </article>
                    <article className='service-icon'>
                        <img className='img-descriptive' src={`${process.env.PUBLIC_URL}/img/conversational_coaching.webp`} alt="Conversation icon" />
                        <h4 style={{color:'white', width:'100%', textAlign: 'start'}}>Conversation English</h4>
                        <p style={{color:'white', width:'100%', textAlign: 'start'}}>{conversationalEnglish}</p>
                    </article>
                    <article className='service-icon'>
                        <img className='img-descriptive' src={`${process.env.PUBLIC_URL}/img/american_coaching.webp`} alt="American Flag" />
                        <h4 style={{color:'white', width:'100%', textAlign: 'start'}}>American Culture and Idioms</h4>
                        <p style={{color:'white', width:'100%', textAlign: 'start'}}>{americanCulture}</p>
                    </article>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Coaching;