const SocialMedia = () => {
    return (
        <div className='social-media-icons'>
            <a href="https://www.facebook.com/profile.php?id=61565214026880" target="_blank" rel="noreferrer">
                <img className="icon" src={`${process.env.PUBLIC_URL}/img/SocialMedia/Facebook.webp`} alt="Facebook icon" />
            </a>
            <a href="https://www.instagram.com/yourvoice.english/" target="_blank" rel="noreferrer">
                <img className="icon" src={`${process.env.PUBLIC_URL}/img/SocialMedia/Instagram.webp`} alt="Instagram icon" />
            </a>
            <a href="https://www.linkedin.com/company/yourvoice-english-coaching/" target="_blank" rel="noreferrer">
                <img className="icon" src={`${process.env.PUBLIC_URL}/img/SocialMedia/Linkedin.webp`} alt="LinkedIn icon" />
            </a>
        </div>
    )
}

export default SocialMedia;