import Navbar from './Navbar';
import Footer from './Footer';
import FreeSessionButton from '../utils/FreeSessionButton';
import './Home.css';

const Home = () => {
    const welcomeMessage = "I'm Savannah White, a certified EFL teacher specializing in business English coaching. Whether you need to improve your conversation skills or excel in Business English, I'm here to help you gain confidence and communicate effectively.";
    const coachingMessage = "At YourVoice English Coaching, you'll experience lessons tailored to your unique needs and goals. My one-on-one approach ensures focused attention and personalized feedback to accelerate your progress. With flexible scheduling and a supportive learning environment, you'll gain the skills you need to succeed in both personal and professional settings.";
    const free_session_url = 'https://yourvoiceenglishcoaching.com/freeSession';
    return (
        <>
            <Navbar />
            <main>
                {/* Welcome Section */}
                <section className='welcome-section'>
                    <div className='content-container'>
                        <img className='img-welcome-section' src={`${process.env.PUBLIC_URL}/img/Savi/savi-1.webp`} alt="Teacher Savannah White" />
                        <article className='welcome-message'>
                            <h2>Welcome!</h2>
                            <p>{welcomeMessage}</p>
                        </article>
                    </div>
                </section>

                {/* Coaching Section */}
                <section className='coaching-section'>
                    <div className='content-container'>
                        <h1>Personalized English Coaching</h1>
                        <p className='coaching-message'>{coachingMessage}</p>
                        <div className='services'>
                            <article className='service-item'>
                                <img className='img-icon' src={`${process.env.PUBLIC_URL}/img/business.webp`} alt="Business icon" />
                                <div className='service-title'>
                                    <h4>Business English</h4>
                                </div>
                                <div className='service-description'>
                                    <p>Master the language of meetings, presentations, and professional communication to help you grow within your field.</p>
                                </div>
                            </article>
                            <article className='service-item'>
                                <img className='img-icon' src={`${process.env.PUBLIC_URL}/img/conversational.webp`} alt="Conversation icon" />
                                <div className='service-title'>
                                    <h4>Conversation English</h4>
                                </div>
                                <div className='service-description'>
                                    <p>Improve your everyday fluency and confidence in speaking with engaging, real-life conversation practice.</p>
                                </div>
                            </article>
                            <article className='service-item'>
                                <img className='img-icon' src={`${process.env.PUBLIC_URL}/img/american.webp`} alt="American Flag" />
                                <div className='service-title'>
                                    <h4>American Culture and Idioms</h4>
                                </div>
                                <div className='service-description'>
                                    <p>Learn the idiomatic expressions and cultural nuances that will help you understand and communicate like a native speaker.</p>
                                </div>
                            </article>
                        </div>
                    </div>
                </section>

                {/* Free Session Section */}
                <section className='schedule-free-session-section'>
                    <div className='content-container'>
                        <h3>Schedule Your</h3>
                        <h1>FREE SESSION TODAY</h1>
                        <FreeSessionButton url={free_session_url}>
                            Claim Free Session
                        </FreeSessionButton>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default Home;