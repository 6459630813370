import Navbar from './Navbar';
import Footer from './Footer';
import SocialMedia from '../utils/SocialMedia';
import './About.css';


const About = () => {
    const aboutMessage1 = 'I\'m Savannah White, a certified EFL tutor and the founder of YourVoice English Coaching. With years of experience teaching English to learners from all over the world, I specialize in helping Hispanic professionals enhance their language skills for personal and professional growth. Originally from North Carolina, USA, I now live in Chile, South America, and am fluent in Spanish, which allows me to connect deeply with my students.'
    const aboutMessage2 = 'My passion is empowering individuals to achieve their goals through language. I provide a safe, supportive environment where learners can experiment, make mistakes, and grow their communication skills. My coaching is dynamic and interactive, focused on real-world communication to help you gain the confidence you need to succeed.'
    const myJourneyMessage = 'My passion for teaching English began when I first moved abroad and experienced how learning a new language could open doors for people both personally and professionally. In Chile, I had the chance to work with children and adults, teaching one-on-one and group classes in the bustling capital of Santiago and the rural landscapes of Chiloé. This experience ignited my love for language coaching and led me to pursue it full-time. After earning my EFL certification, I began coaching students from diverse backgrounds, always focusing on creating a safe, supportive space where they can build their confidence. I’ve had the privilege of working with professionals across various industries, helping them not only improve their English but also navigate the cultural nuances that come with mastering the language. Being part of the growth process for so many talented and motivated individuals is truly an honor.'
    return (
        <div className='about-page'>
            <Navbar />
            <main>
                <section className='about-section'>
                    <div className='content-container'>
                        <aside className='about-section-img'>
                            <img className='img-about' src={`${process.env.PUBLIC_URL}/img/Savi/savi-2.webp`} alt="Teacher Savannah White" />
                        </aside>
                        <article className='content-about'>
                            <h1>Hello...</h1>
                            <p>{aboutMessage1} <br /> {aboutMessage2} </p>
                            <SocialMedia />
                        </article>
                    </div>
                </section>
                <section className='my-journey-section'>
                    <div className='content-container'>
                        <div className='title-left'>
                            <h2>My Journey</h2>
                        </div>
                        <p>{myJourneyMessage}</p>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default About;