import Navbar from './Navbar';
import Footer from './Footer';
import SocialMedia from '../utils/SocialMedia';
import FreeSessionButton from '../utils/FreeSessionButton';
import './FreeSession.css';

const FreeSession = () => {
        
 
   
    const sessionTitle = 'Claim your FREE Coaching Session';
    const sessionMessage1 = 'Let me help you find your voice in English.';
    const sessionMessage2 = 'Ready to take the first step in improving your English? I offer a free session to assess your level, discuss your goals, and create a personalized coaching plan. Whether you want to improve business communication, everyday conversations, or dive into American culture and idioms, this session will show how we can work together to meet your needs.';
    const sessionMessage3 = 'To reserve your free session, simply press the “Schedule Now” botton below. Then, choose a day and time that works for you. Once you’ve reserved your session, I’ll email you with more information and a meeting link. See you soon!';
    const free_session_url = 'https://yourvoiceenglishcoaching.as.me/freesession';
    return (
        <div className='free-session'>
            <Navbar />
            <main className='free-session-component'>
                <section className='free-session-section'>
                    <article className='session-info'>
                        <h1>{sessionTitle}</h1>
                        <p>{sessionMessage1}</p>
                        <p>{sessionMessage2}</p>
                        <p>{sessionMessage3}</p>
                        <form className='session-form'>
                            <FreeSessionButton url={free_session_url}>
                                Schedule Now
                            </FreeSessionButton>
                        </form>
                        <SocialMedia />
                    </article>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default FreeSession;