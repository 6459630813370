import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Coaching from './components/Coaching';
import FreeSession from './components/FreeSession';

function App() {
  const basename = process.env.NODE_ENV === 'production' ? '/' : '';
  return (
    <Router basename={basename}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/coaching" element={<Coaching />} /> 
        <Route path="/freeSession" element={<FreeSession />} />
      </Routes>
    </Router>
  );
}

export default App;