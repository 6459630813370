import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [closing, setClosing] = useState(false);

    const toggleMenu = () => {
        if (menuOpen) {
            setClosing(true); // Activa la animación de cierre
            setTimeout(() => {
                setMenuOpen(false); // Cierra el menú después de la animación
                setClosing(false); // Resetea el estado de cierre
            }, 400); // Duración de la animación en milisegundos
        } else {
            setMenuOpen(true);
        }
    };

    return (
        <header>
            <div className="logo">
                <NavLink to='/'>
                    <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="YourVoice English Coaching Logo" />
                </NavLink>
            </div>

            {/* Botón de menú hamburguesa */}
            <div className={`hamburger-menu ${menuOpen ? '' : 'active'}`} onClick={toggleMenu}>
                ☰
            </div>

            {/* Menú desplegable con botón de cierre */}
            <nav className={`nav-container ${menuOpen ? 'active' : ''} ${closing ? 'closing' : ''}`}>
                <button className="close-button" onClick={toggleMenu}>✕</button>
                <NavLink className='nav-link' to="/" onClick={toggleMenu}>Home</NavLink>
                <NavLink className='nav-link' to="/about" onClick={toggleMenu}>About</NavLink>
                <NavLink className='nav-link' to="/coaching" onClick={toggleMenu}>Coaching</NavLink>
                <NavLink className='nav-link' to="/freeSession" onClick={toggleMenu}>Free Session</NavLink>
            </nav>
        </header>
    );
}

export default Navbar;